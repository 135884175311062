<template>
    <div class="home">

        <v-row>
            <v-col cols="10" offset="1">

                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="SUMMARY"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7">
                        <v-row>
                            <v-col>

                                <TitleText title="Contactgegevens"
                                           text="U kunt uw contactgevevens in de onderstaande velden invullen."
                                ></TitleText>

                                <v-form ref="contactForm">
                                    <ContactForm></ContactForm>
                                </v-form>
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="6">
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col cols="6" align="right">
                                <v-btn class="rounded-lg text-uppercase filled-contact"
                                       color="primary"
                                       type="button"
                                       height="41px"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: false, showToggle: true}"
                        :maintenance="{show: true, toggle: false, showToggle: true}"
                        :location="{show: true, showToggle: false}"
                        :wait="{show: true, showToggle: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import ContactForm from "@/components/forms/Contact";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";

export default {
    "name": "AppointmentType",
    "components": {
        BreadCrumbs,
        Sidebar,
        ContactForm,
        TitleText
    },
    data () {
        return {};
    },
    "computed": {
        appointmentType () {
            return this.$store.state.car.appointmentType;
        },
        waitForMaintenance () {
            return this.$store.state.car.waitForMaintenance;
        }
    },
    "methods": {
        previousPage () {
            if (this.waitForMaintenance) {
                this.$router.push({"name": "DateTime"});
            } else {
                this.$router.push({"name": "VehicleReplacement"});
            }
        },
        nextPage () {
            if (!this.$refs.contactForm.validate()) {
                return;
            }
            this.$router.push({"name": "Summary"});
        }
    }
};
</script>
