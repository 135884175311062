<template>
  <div>
    <v-sheet outlined color="darkblue" class="mb-5">
      <v-card tile>
        <v-card-subtitle class="text-uppercase darkblue white--text">
          <v-icon color="white">mdi-calendar</v-icon>
          Locatie en tijd
          <button v-if="this.toggleShown" class="float-right" @click="toggleCard()">
            <v-icon color="white" v-show="!cardShown">mdi-chevron-down</v-icon>
            <v-icon color="white" v-show="cardShown">mdi-chevron-up</v-icon>
          </button>
        </v-card-subtitle>
        <v-card-text class="dark--text" v-show="cardShown">
          <v-row dense>
            <v-col>Vestiging:</v-col>
            <v-col align="right">{{ this.currentEstablishmentName }}</v-col>
          </v-row>
          <v-row dense v-if="currentMechanic">
            <v-col>Uw technicus:</v-col>
            <v-col align="right">{{ currentMechanic.Name }}</v-col>
          </v-row>
          <v-row dense v-if="maintenanceDate !== null">
            <v-col>Datum:</v-col>
            <v-col align="right">{{ maintenanceDate }}</v-col>
          </v-row>
          <v-row dense v-if="maintenanceTime !== null">
            <v-col>Tijd:</v-col>
            <v-col align="right">{{ maintenanceTime }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>

import SidebarCard from "@/components/abstract/SidebarCard";
import moment from "moment";

export default {
    "extends": SidebarCard,
    "name": "SidebarLocationTime",
    "computed": {
        currentMechanic () {
            return this.$store.state.car.currentMechanic;
        },
        maintenanceDate () {
            if (!this.$store.state.car.maintenanceDate) {
                return null;
            }
            return moment(this.$store.state.car.maintenanceDate).locale("nl").format("DD MMMM YYYY");
        },
        maintenanceTime () {
            return this.$store.state.car.maintenanceTime;
        },
        establishments () {
            return this.$store.state.establishmentInfo.Establishments;
        },
        currentEstablishmentName () {
            const establishment = this.establishments
                .find((est) => est.Id === this.$store.state.car.currentEstablishmentId);
            if (establishment) {
                return establishment.Name.replace(
                    "VOLVO",
                    ""
                ).replace(
                    "B.V.",
                    ""
                ).replace(
                    "BV",
                    ""
                );
            }
            return "";
        }
    },
    "methods": {}
};
</script>

<style scoped>
.v-card__subtitle {
  padding: 10px;
}
</style>
