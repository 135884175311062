<template>
    <div class="page">
        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="LOCATIONTIME"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7">
                        <TitleText title="Datum en tijd"></TitleText>
                        <p v-if="totalTime">De verwachte werkzaamhedenduur is {{timeConvert(totalTime)}}.</p>
                        <br />

                        <v-divider></v-divider>

                        <Planner :next-route="nextRouteAfterPlanning"></Planner>

                        <v-row>
                            <v-col cols="6">
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>
                            <v-col cols="6" align="right">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       height="41px"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: false, showToggle: true}"
                        :maintenance="{show: true, toggle: false, showToggle: true}"
                        :location="{show: true, showToggle: false}"
                        :wait="{show: true, showToggle: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Planner from "@/components/general/Planner";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";

export default {
    "name": "DateTime",
    "components": {
        BreadCrumbs,
        Planner,
        Sidebar,
        TitleText
    },
    data () {
        return {
            "showNextPageButton": false
        };
    },
    "computed": {
        ...mapGetters({
            "totalTime": "car/totalTimeUnits"
        }),
        existingCustomer () {
            return this.$store.state.car.existingCustomer;
        },
        appointmentType () {
            return this.$store.state.car.appointmentType;
        },
        waitForMaintenance () {
            return this.$store.state.car.waitForMaintenance;
        },
        nextRouteAfterPlanning () {
            if (this.waitForMaintenance) {
                if (!this.existingCustomer) {
                    return "ContactInfo";
                }
                return "Summary";
            }
            return "VehicleReplacement";

        }
    },
    "methods": {
        // eslint-disable-next-line consistent-return
        timeConvert (n) {
            // eslint-disable-next-line no-param-reassign
            n *= 30;
            const hours = (n / 60);
            const rhours = Math.floor(hours);
            const minutes = (hours - rhours) * 60;
            const rminutes = Math.round(minutes);
            if (rhours === 0 && rminutes > 0) {
                return `${rminutes} minuten`;
            } else if (rhours > 0 && rminutes > 0) {
                return `${rhours} uur en ${rminutes} minuten`;
            } else if (rhours > 0 && rminutes === 0) {
                return `${rhours} uur`;
            }
        },
        previousPage () {
            this.$router.push({"name": "Wait"});
        },
        nextPage () {
            let available = false;
            for (const timeObject of this.$store.state.car.currentTimes) {
                if (timeObject.Time === this.$store.state.car.currentTime) {
                    available = timeObject.Available;
                }
            }
            if (!this.$store.state.car.maintenanceDate && !available) {
                alert("Selecteer eerst een datum en daarna een geschikte tijd.");
            } else if (this.$store.state.car.maintenanceDate && !available) {
                alert("Selecteer een beschikbare tijd voor deze datum.");
            }
            return false;
        }
    }
};

</script>
