<template>
    <div>
        <v-text-field
            label="Bedrijfsnaam"
            :value="appointmentFields.CompanyName"
            @change="updatePathValue(['Appointment.Fields.CompanyName'], $event)"
        ></v-text-field>

        <v-select
            label="Aanhef"
            :value="appointmentFields.Gender"
            @change="updatePathValue(['Appointment.Fields.Salutation'], salutationItems
            .find((sal) => (sal.id === $event)).label)"
            :items="salutationItems"
            item-text="label"
            item-value="id"></v-select>

        <v-text-field
            label="Naam"
            :value="appointmentFields.Name"
            @change="updatePathValue(['Appointment.Fields.Name'], $event)"
            :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
            label="Postcode"
            :value="appointmentFields.ZipCode"
            @change="updatePathValue(['Appointment.Fields.ZipCode'], $event)"
            :rules="[rules.required]"
        ></v-text-field>

        <v-row>
            <v-col cols="8">
                <v-text-field
                    label="Straatnaam"
                    :value="appointmentFields.Street"
                    @change="updatePathValue(['Appointment.Fields.Street'], $event)"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Huisnummer"
                    :value="appointmentFields.HouseNumber"
                    @change="updatePathValue(
                        ['Appointment.Fields.HouseNumber'],
                        $event
                    )"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-text-field
            label="Plaats"
            :value="appointmentFields.City"
            @change="updatePathValue(['Appointment.Fields.City'], $event)"
            :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
            label="Telefoonnummer"
            type="number"
            :value="appointmentFields.Phone"
            @change="updatePathValue(['Appointment.Fields.Phone'], $event)"
            :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
            label="E-mailadres"
            :value="appointmentFields.Email"
            @change="updatePathValue(['Appointment.Fields.Email'], $event)"
            :rules="[rules.required, rules.email]"
        ></v-text-field>
    </div>
</template>

<script>

export default {
    "name": "ContactForm",
    "components": {},
    "computed": {
        appointmentFields () {
            return this.$store.state.car.Appointment.Fields;
        }
    },
    data () {
        return {
            "rules": {
                "email": (value) => !value || (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/u).test(value) || "E-mail is niet correct.",
                "required": (value) => Boolean(value) || "Dit is een verplicht veld."
            },
            "salutationItems": [
                {
                    "id": "M",
                    "label": "Heer"
                }, {
                    "id": "V",
                    "label": "Mevrouw"
                }
            ]
        };
    },
    "methods": {
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        }
    }
};
</script>
