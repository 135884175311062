import api from "../../helpers/apiHandler";

const getDefaultState = () => ({
    "texts": []
});

const state = getDefaultState();

const mutations = {
    SET_TEXTS (state, texts) {
        state.texts = texts;
    },
    apiFailure (newState, value) {
        return value;
    }
};

const getters = {
    "texts": (state) => state.texts,
    "getTextByName": (state) => (name) => {
        const text = state.texts.find((t) => (t.name === name));
        if (text) {
            return text.content;
        }
        return "";
    }
};

const actions = {
    async getTexts ({commit}) {
        await api.get(
            "v1/text",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                commit(
                    "SET_TEXTS",
                    response.data
                );
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
