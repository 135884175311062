import api from "../../helpers/apiHandler";

// eslint-disable-next-line max-lines-per-function
const getDefaultState = () => ({
    "Establishments": [
        {
            "Id": 3264,
            "Name": "Nieuwenhuijse Apeldoorn TEST",
            "Address": "Paramariboweg",
            "HouseNumber": "68",
            "ZipCode": "7333 PB",
            "City": "Apeldoorn",
            "Language": 31,
            "Module": 1,
            "Texts": {},
            "Makes": [],
            "ReplacementVehicles": [],
            "Jobs": [],
            "NumberOfMonths": 3,
            "WidthCalendar": 600,
            "HeightCalendar": 400,
            "DayNumberColor": "000000",
            "TodayColor": "ff0000",
            "PastColor": "ebebeb",
            "TimeSelectColor": null,
            "CellDayColor": "6390a0",
            "DayTextColor": "ffffff",
            "CellWeekColor": "6390a0",
            "WeekTextColor": "ffffff",
            "UnavailableColor": "ebebeb",
            "CurrentUnavailableColor": "ff5050",
            "SmallAvailableColor": "f5a560",
            "AvailableColor": "cce9a6",
            "AvailableTextColor": null,
            "JobsTitle": "Selecteer eerst uw Personal Service Technician en gewenste werkzaamheden*",
            "ReplacementVehiclesTitle": "Gelieve hieronder uw voorkeur aangeven.",
            "ContactTitle": "Uw contactgegevens",
            "Legend": 0,
            "ReplacementVehiclesIsMandatory": 1,
            "RegistrationNumberOnTop": 0,
            "DefaultJobId": 0,
            "Captcha": 0,
            "CheckboxIsMandatory": 1,
            "ThankYouPageUrl": "https://www.nieuwenhuijse.nl/afspraakbevestiging-apeldoorn-dank-u/",
            "CellColor": null,
            "TextColor": "",
            "HourColor": null,
            "HourTextColor": null,
            "LastMinuteModule": 0,
            "TextColor_LMD": "000000",
            "BGColor_LMD": "F8ED79",
            "NoLabel_LMD": 0,
            "NoWeekend_LMD": 0,
            "NoPreferedTime": 0,
            "SelectionMode": 0,
            "LastMinuteDeal": 0,
            "RaiseLimited": 0,
            "RaiseWeekend": 0,
            "DaysUnavailable": 1,
            "DaysLimited": 0,
            "GoogleAnalyticsCode": "UA-1920545-1",
            "EmailAddresses": [
                "bstam@nieuwenhuijse.nl",
                "logs@nieuwenhuijse.nl"
            ],
            "PrivacyMessage": "Volvo Car Nederland zal uw persoonsgegevens opslaan en verwerken volgens informatiebericht. Volvo Car Nederland zal uw persoonsgegevens ook delen met uw geselecteerde dealer om uw verzoek te verwerken en op te volgen. Door het indienen van deze aanvraag geeft u toestemming voor de verwerking.",
            "PrivacyUrl": "https://www.volvocars.com/nl/footer/information-notice-service-booking",
            "VehicleType": 1,
            "PhotoUrl": "https://assets.nieuwenhuijse.nl/afspraakbevestiging/onlineplanner/apeldoorn.png",
            "PhoneNumber": "0384558875",
            "FirstAvailableDate": "01-01-2022"
        }
    ]
});

const state = getDefaultState();

const mutations = {
    SET_ESTABLISHMENTS (state, data) {
        state.Establishments = data;
    },
    UPDATE_ESTABLISHMENTS (state, data) {
        state.Establishments = data;
    },
    RESET_STATE (state) {
        Object.assign(
            state,
            getDefaultState()
        );
    },
    apiFailure (newState, value) {
        return value;
    }
};

const getters = {
    "currentEstablishment": (state) => (id) => (state.Establishments.find((est) => est.Id === id)),
    "getPoleStarEstablishments": (state) => (state.Establishments.filter((est) => (est.Makes.find((make) => make.Name.toUpperCase() === "POLESTAR")))),
    "getVolvoEstablishments": (state) => (state.Establishments.filter((est) => (est.Makes.find((make) => make.Name.toUpperCase() === "VOLVO")))),
    "getEstablishmentsByMake": (state) => (make) => (state.Establishments.filter((est) => (est.Makes.find((m) => m.Name.toUpperCase() === make.toUpperCase()))))
};

const actions = {
    getEstablishmentInfo ({commit}) {
        return api.get(
            "v1/planit/establishmentinfo",
            {
                "headers": {
                    "Authorization": `Bearer ${localStorage.getItem("jwt")}`
                }
            }
        )
            .then((response) => {
                if (typeof (response.data) !== "undefined" && response.data.length > 0) {
                    const establishments = response.data
                        .filter((est) => (est.Makes.find((m) => m.Name.toUpperCase() !== "POLESTAR")));
                    commit(
                        "SET_ESTABLISHMENTS",
                        establishments
                    );
                }
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    },
    resetState ({commit}) {
        commit("RESET_STATE");
    },
    getFirstAvailablePlanDate ({commit, rootState}) {
        const params =
            {"products": rootState.car.chosenProducts};
        return api.post(
            "v1/planit/firstavailableplandate",
            params,
            {
                "Authorization": `Bearer ${localStorage.getItem("jwt")}`
            }
        )
            .then((response) => {
                if (typeof (response.data) !== "undefined" && response.data.length > 0) {
                    const esta = response.data
                        .filter((est) => (est.Makes.find((m) => m.Name.toUpperCase() !== "POLESTAR")));
                    commit(
                        "UPDATE_ESTABLISHMENTS",
                        esta
                    );
                }
            })
            .catch((error) => commit(
                "apiFailure",
                error.message
            ));
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
